import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

export const toastr = (msg = '', type = 'success', options = {}) => {
  toast[type](msg, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    ...options,
  });
};
